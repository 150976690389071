import { FC } from "react";
import {
	StreamingLadder,
} from "@components/video";

const Page:FC = () => {
	return (
		<div>
			<StreamingLadder/>
		</div>
	);
};

export default Page;